import { Box, Collapse } from "@mui/material";
import { HeaderProps } from "./Header";
import Image from "next/image";
import React from "react";
import { DownArrow, UpArrow } from "./HeaderDesktop";
import { Link as MUILink } from "@mui/material";
import Link from "next/link";
import { BuilderBlocks } from "@builder.io/react";

export default function HeaderMobile(props: HeaderProps) {
  const hamburgerIconUrl =
    "https://cdn.builder.io/api/v1/image/assets%2Fc9fcfa3fdc8d44d2a2c2522da436aabc%2F78019cd5e1f444bfaf55794387fa3014?width=30";
  const xIconUrl =
    "https://cdn.builder.io/api/v1/image/assets%2Fc9fcfa3fdc8d44d2a2c2522da436aabc%2Fa5fee40503144c3489cdf5749b9fd92c";

  const [isOpen, setIsOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(props.tabs[0]);
  const [openTabs, setOpenTabs] = React.useState<boolean[]>(
    props.tabs.map((_) => false)
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <MUILink component={Link} href="/" data-testid="header-logo-mobile">
          <Image
            style={{
              marginLeft: "10px",
            }}
            height="36"
            width="160"
            alt="Pivot Health Logo"
            src="https://cdn.builder.io/api/v1/image/assets%2Fc9fcfa3fdc8d44d2a2c2522da436aabc%2Fecb7c86153ed4fb7ae1ba9e28998fbec?width=160"
          />
        </MUILink>
        <Image
          style={{
            marginRight: "20px",
            cursor: "pointer",
            zIndex: 4,
          }}
          onClick={() => setIsOpen(!isOpen)}
          height="30"
          width="30"
          alt={isOpen ? "X Icon" : "Hamburger Icon"}
          src={isOpen ? xIconUrl : hamburgerIconUrl}
          data-testid="header-mobile-menu-open"
        />
      </Box>
      <Collapse timeout={0} in={isOpen}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center",
            justifyItems: "center",
            width: "100%",
            backgroundColor: "#fff",
            zIndex: 999,
            position: "absolute",
          }}
        >
          <Box
            component={Link}
            href="https://www.pivothealth.com/product-portfolio"
            sx={{
              border: "1px solid #303a52",
              borderRadius: "105px",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
              cursor: "pointer",
              textDecoration: "none",
              color: "#303a52",
              transition: "background-color 0.15s ease-in-out",
              marginTop: "20px",
              marginBottom: "20px",
              backgroundColor: "#ffc057",
            }}
            data-testid="header-cta-mobile"
          >
            Find Your Plan
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              cursor: "pointer",
              display: "flex",
            }}
          >
            <MUILink component={Link} href="tel:8772460106">
              <Image
                alt="Phone Icon"
                src="https://cdn.builder.io/api/v1/image/assets%2Fc9fcfa3fdc8d44d2a2c2522da436aabc%2F13353a2ac884427b8cbb97b654a1c143?width=16"
                width="16"
                height="20"
                style={{
                  marginTop: "2px",
                  marginRight: "5px",
                }}
              />
            </MUILink>
            <MUILink
              component={Link}
              href="tel:8772460106"
              sx={{
                textDecoration: "none",
                color: "#303a52",
              }}
            >
              <Box>877-246-0106</Box>
            </MUILink>
          </Box>
          {props.tabs.map((tab, index) => {
            return (
              <Box
                key={index}
                sx={{
                  cursor: "pointer",
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                }}
              >
                {tab.isExpandable ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        const currentTabState = Array.from(openTabs);
                        currentTabState[index] = !currentTabState[index];
                        setOpenTabs(currentTabState);
                        setActiveTab(tab);
                      }}
                    >
                      {tab.label}{" "}
                      {tab.label === activeTab.label && openTabs[index]
                        ? DownArrow("18px")
                        : UpArrow("18px")}
                    </Box>
                    <Collapse
                      in={openTabs[index]}
                      sx={{ backgroundColor: props.backgroundColor }}
                    >
                      <BuilderBlocks
                        /* c8 ignore next 1 */
                        parentElementId={props.builderBlock?.id}
                        dataPath={`component.options.tabs.${index}.content`}
                        blocks={tab.content}
                      />
                    </Collapse>
                  </>
                ) : (
                  <MUILink
                    href={tab.linkURL ? tab.linkURL : "#"}
                    component={tab.externalLink ? "a" : Link}
                    sx={{ color: "#303a52", textDecoration: "none" }}
                    data-testid={`${tab.label}-mobile`}
                  >
                    {" "}
                    {tab.label}{" "}
                  </MUILink>
                )}
              </Box>
            );
          })}
        </Box>
      </Collapse>
    </>
  );
}
